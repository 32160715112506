<template>
  <admin-fillall-layout>
    <back-button @back-click="back" />
    <div class="profile" id="device-profile">
      <div class="leftPadding">
        <el-row
          class="header-row"
          type="flex"
          align="center"
          justify="space-between"
        >
          <div>
            <img
              v-if="droneData.detail!=='inactive' && droneData.detail!=='offline'"
              class="thumbnail"
              src="/asserts/icon/devices-drone-icon.svg"
            >
            <img
              v-if="droneData.detail=='inactive'"
              class="thumbnail"
              src="/asserts/icon/devices-drone-inactive-icon.svg"
            >
            <img
              v-if="droneData.detail=='offline'"
              class="thumbnail opaque"
              src="/asserts/icon/devices-drone-icon.svg"
            >
          </div>
          <div class="header-row__name tooltip">
            <div class="valueLabel">
              {{ droneData.name }}
            </div>
            <span class="tooltip-text">{{ droneData.name }}</span>
            <hr>
          </div>
        </el-row>

        <!-- <el-row class="first-topPadding" type="flex" align="bottom" justify="space-between" >

          Charge          <el-switch v-model="drone.charge"></el-switch>
        </el-row>
        <el-row style="padding-right:35%;">
          <hr />
        </el-row> -->
        <el-row
          class="topPadding"
          type="flex"
          align="bottom"
          justify="space-between"
        >
          {{ $t('page.device.dialog.inActive') }}        <div class="valueLabel">
            {{ !droneData.inactive ? $t('button.on'):$t('button.off') }}
          </div>
        </el-row>
        <el-row style="padding-right:35%;">
          <hr>
        </el-row>
        <el-row
          class="topPadding"
          type="flex"
          align="bottom"
          justify="space-between"
        >
          {{ $t('page.device.dialog.powerOff') }}        <div class="valueLabel">
            {{ droneData.detail!='offline' && droneData.detail!='inactive' ? $t('button.on'):$t('button.off') }}
          </div>
        </el-row>
        <el-row style="padding-right:35%;">
          <hr>
        </el-row>
        <el-row
          class="topPadding"
          type="flex"
          align="bottom"
          justify="space-between"
        >
          {{ $t('header.Location') }}        <div class="valueLabel">
            {{ droneData.group }}
          </div>
        </el-row>
        <el-row style="padding-right:35%;">
          <hr>
        </el-row>
        <el-row
          class="topPadding"
          type="flex"
          align="bottom"
          justify="space-between"
        >
          {{ $t('page.mission.title') }}<div class="valueLabel">
            {{ (droneData.mission) ?droneData.mission.name :'' }}
          </div>
        </el-row>
        <el-row style="padding-right:35%;">
          <hr>
        </el-row>
        <el-row
          class="topPadding"
          type="flex"
          align="bottom"
          justify="space-between"
        >
          {{ $t('page.device.dialog.type') }}<div class="valueLabel">
            {{ $t(`page.device.dialog.${drone.type}`) }}
          </div>
        </el-row>
        <el-row style="padding-right:35%;">
          <hr>
        </el-row>
        <el-row
          class="topPadding"
          type="flex"
          align="bottom"
          justify="space-between"
        >
          {{ $t('page.device.dialog.mac') }}<div class="valueLabel">
            {{ droneData.address }}
          </div>
        </el-row>
        <el-row style="padding-right:35%;">
          <hr>
        </el-row>
        <el-row
          class="topPadding"
          type="flex"
          align="bottom"
          justify="space-between"
        >
          {{ $t('page.device.dialog.rgb') }}
          <copy-url :url="droneData.rgb_url.rtmp">
            <el-button
              size="mini"
              :no-border="true"
              icon=""
              class="image-button button action-button url"
            />
          </copy-url>
        </el-row>
        <el-row style="padding-right:35%;">
          <hr>
        </el-row>
        <el-row
          class="topPadding"
          type="flex"
          align="bottom"
          justify="space-between"
        >
          {{ $t('page.device.dialog.thermal') }}
          <copy-url :url="droneData.thermal_url.rtmp">
            <el-button
              size="mini"
              :no-border="true"
              icon=""
              class="image-button button action-button url"
            />
          </copy-url>
        </el-row>
        <el-row style="padding-right:35%;">
          <hr>
        </el-row>
      </div>
    </div>
  </admin-fillall-layout>
</template>

<script>

import droneApi from '@/services/api/domain/group/drone';
import nestApi from '@/services/api/domain/group/nest';
import { CopyObject } from '@/utils/common.js';
import BackButton from '@/components/Maps/BackButton.vue';
import { mapActions, mapGetters } from 'vuex';
import CopyUrl from '@/components/CopyUrl.vue';

export default {
  components: {
    BackButton,
    CopyUrl,
  },
  computed: {
    ...mapGetters({
      groupId: 'group/getGroupId',
      droneMode: 'drone/getMode',
      droneData: 'drone/getDrone',
      droneShow: 'drone/shouldShow',
      authUser: 'auth/user',
      group: 'group/getGroup',
      nestMode: 'nest/getMode',
      nestData: 'nest/getNest',
      nestShow: 'nest/shouldShow',
    }),
  },

  data() {
    return {
      // googleMapKey: config.googleMapKey,
      deleteShow: false,
      nestDeleteShow: false,
      droneId: null,
      nestId: null,
      source: {
        items: [],
        nestItems: [],
        paging: {
          total: 0,
        },
      },
      filter: {},
      page: {
        loading: false,
      },
      drone: CopyObject(this.$store.state.drone.drone),
      nest: CopyObject(this.$store.state.nest.nest),
      //   periodUnit: this.$store.state.mission.periodUnit
    };
  },
  methods: {
    back() {
      this.$router.push({
        name: 'device',
      });
    },
    ...mapActions({
      setDroneMode: 'drone/setMode',
      setDrone: 'drone/setDrone',
      setNestMode: 'nest/setMode',
      setNest: 'nest/setNest',
    }),
    copyToClip(rIndex) {
      const textArea = document.querySelector(`#event-url-${rIndex}`);
      textArea.select();
      document.execCommand('copy');
    },
  },
  mounted() {

  },
};
</script>

<style lang="scss">

#device-profile{
  width: 100%;
  height: 125%;
  background-color:#f3f3f3;

  .leftPadding{
    position: relative;
    margin-left: 30%;
    top :72px;

    img.thumbnail{
      height: 88px;
    }
    img.opaque{
      opacity: 0.4;
    }

    .header-row{
      @include profile-header-row;
      .header-row__name {
        @include profile-header-row__name;
        .valueLabel{
          @include profile-header-row-valueLabel;
          @include profile-valueLabel-ellipsis;
        }
      }
    }

    .first-topPadding{
      margin-top: 52px;
      padding-right:35%;
    }
    .topPadding{
      @include profile-topPadding;
    }
  }

  .valueLabel{
    @include profile-valueLabel;
  }

  span.popover-button {
    display: flex;
  }

  .trigger-url-popover {
    padding: 32px 20px;
    .popover-url {
      display: flex;
      min-height: 20px;
      .popover-text {
        flex-grow: 1;
        word-break: break-all;
        padding-right: 15px;
        .hidden-text-area {
          border: 0;
          width: 100%;
          resize: none;
        }
      }

      .popover-copy {
        flex-grow: 0;
        .el-button--mini {
          width: 76px;
          height: 24px;
        }
      }
    }
  }

  .tooltip {
    position: relative;
  }

  .tooltip .tooltip-text {
    @include tooltip-text-style;
    top: 43%;
    left: 66%;
  }

  .tooltip:hover .tooltip-text {
    @include tooltip-hover-style;
  }

}
</style>
